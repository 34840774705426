import { on } from 'delegated-events'
import 'what-input'

const _wr = function (type) {
  const orig = history[type]

  return function () {
    const rv = orig.apply(this, arguments)
    const e = new Event(type)
    e.arguments = arguments
    window.dispatchEvent(e)
    return rv
  }
}

window.history.replaceState = _wr('replaceState')

const handleModalUrl = () => {
  const path = window.location.pathname.replace('/', '')

  const targetModal = document.querySelector(`[data-modal="${path}"]`)
  const currentModal = document.querySelector(`[data-modal].is-active`)

  if (targetModal) {
    targetModal.classList.add('is-active')
  }

  if (currentModal) {
    currentModal.classList.remove('is-active')
  }
}

window.addEventListener('replaceState', handleModalUrl)

handleModalUrl()

on('click', '[data-modal-trigger]', (e) => {
  if (e.target.closest('[data-modal-trigger]')) {
    e.preventDefault()

    const anchor = e.target.closest('[data-modal-trigger]')

    window.history.replaceState({}, null, anchor.href)
  }
})

on('click', '.modal-close', () => {
  window.history.replaceState({}, null, '/')
})
